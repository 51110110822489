:root {
    --color-primary: #6741d9;
    --color-primary-light: #7950f2;
    --color-text: #dee2e6;
    --color-text-dark: #adb5bd;
    --color-background-100: #343a40;
    --color-background-500: #2b3035;
    --color-background-900: #212529;
    --color-red: #fa5252;
    --color-red-dark: #e03131;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

    color: var(--color-text);
    background-color: var(--color-background-900);
    padding: 2.4rem;
}

.fullpage{
    height: 100% !important;
}

/* ******* */

.nav-bar {
    display: flex;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    height: 7.2rem;
    width: 100%;
    padding: 0 3.2rem;
    background-color: var(--color-primary);
    border-radius: 0.9rem;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
}


.footer{
    /*margin-top: -200px;*/
    display: flex;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    height: 3.1rem;
    /*width: 100%;*/
    padding: 0 3.2rem;
    background-color: var(--color-primary);
    border-radius: 0.9rem;
    width: 95%;
    /*position:absolute;*/
    /*bottom:-10%;*/
}

.main {
    margin-top: 2.4rem;
    height: calc(100vh - 7.2rem - 3 * 2.4rem);
    display: flex;
    gap: 2.4rem;
    justify-content: center;
}

.box {
    width: 42rem;
    max-width: 42rem;
    background-color: var(--color-background-500);
    border-radius: 0.9rem;
    overflow: scroll;
    position: relative;
}

.loader {
    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 600;
    margin: 4.8rem;
}

.error {
    text-align: center;
    font-size: 2rem;
    padding: 4.8rem;
}

.success {
    color: greenyellow;
    text-align: center;
    font-size: 2rem;
    padding: 4.8rem;
}

/* ******* */

.logo {
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.logo span {
    font-size: 3.2rem;
}

.logo h1 {
    font-size: 2.4rem;
    font-weight: 600;
    color: #fff;
}

.rep {
    font-size: 2.4rem;
    font-weight: 600;
    /*color: #fff;*/
}

.multi-color-heading {
    background: linear-gradient(90deg, #ffffff, #ffffff 5%, #ea4335 5%, #ea4335 15%, #34a853 15%, #34a853 25%, #fbbc05 25%, #fbbc05 35%, #ea4335 35%, #ea4335 45%, #4285f4 45%, #4285f4 55%, #34a853 55%, #34a853 65%, #fbbc05 65%, #fbbc05 75%, #ea4335 75%, #ea4335 85%, #4285f4 85%, #4285f4 95%, #34a853 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 2.4rem;
    /*font-weight: 600;*/
}

.search {
    justify-self: center;
    border: none;
    padding: 1.1rem 1.6rem;
    font-size: 1.8rem;
    border-radius: 0.7rem;
    width: 40rem;
    transition: all 0.3s;
    color: var(--color-text);

    /* background-color: var(--color-background-900); */
    background-color: var(--color-primary-light);
}

.search::placeholder {
    color: var(--color-text-dark);
}

.search:focus {
    outline: none;
    box-shadow: 0 2.4rem 2.4rem rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
}

.num-results {
    justify-self: end;
    font-size: 1.8rem;
}

.btn-toggle {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    height: 2.4rem;
    aspect-ratio: 1;
    border-radius: 50%;
    border: none;
    background-color: var(--color-background-900);
    color: var(--color-text);
    font-size: 4.4rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 999;
}

.list {
    list-style: none;
    padding: 0.8rem 0;
    overflow: scroll;
}


.list li {
    position: relative;
    display: grid;
    grid-template-columns: 4rem 1fr;
    grid-template-rows: auto auto;
    column-gap: 2.4rem;
    font-size: 1.6rem;
    align-items: center;

    padding: 1.6rem 3.2rem;
    border-bottom: 1px solid var(--color-background-100);
}

.list.list-communities li {
    cursor: pointer;
    transition: all 0.3s;
}

.list.list-communities li:hover {
    background-color: var(--color-background-100);
}

.list img {
    width: 100%;
    grid-row: 1 / -1;
}

.list h3 {
    font-size: 1.8rem;
}

.list div {
    display: contents;
    align-items: center;
    gap: 2.4rem;
}

.list p {
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.btn-delete {
    position: absolute;
    right: 2.4rem;

    border: none;
    border-radius: 10rem;
    font-size: 1.4rem;
    padding: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;

    height: 4.0rem;
    aspect-ratio: 1;
    /*border-radius: 100%;*/
    /*border: none;*/
    background-color: var(--color-red);
    color: var(--color-background-900);
    /*font-size: 1.0rem;*/
    /*font-weight: bold;*/
    /*cursor: pointer;*/
    /*transition: all 0.3s;*/
}

.btn-delete:hover {
    background-color: var(--color-red-dark);
}

/* ******* */

.summary {
    padding: 2.2rem 3.2rem 1.8rem 3.2rem;
    border-radius: 0.9rem;
    background-color: var(--color-background-100);
    box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.2);
}

.summary h2 {
    text-transform: uppercase;
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
}

.summary div {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    font-size: 1.6rem;
    font-weight: 600;
}

.summary p {
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

/* ******* */

p {
    font-size: 2.0rem;
    font-weight: 300;
    text-align: center;
    background-clip: text;
    background-image: linear-gradient(to right bottom, #80dfe1, #4e7afa);
    color: transparent;
}

label {
    font-weight: 500;
    font-size: medium;
}

label::first-letter {
    display: inline-block;
    margin-right: 4px;
    font-size: 1.8rem;
}

.details {
    line-height: 1.4;
    font-size: 1.4rem;
}

.details header {
    display: flex;
}

.details section {
    padding: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.details img {
    width: 33%;
}

.details-overview {
    width: 100%;
    padding: 2.4rem 3rem;
    background-color: var(--color-background-100);
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}

.details-overview h2 {
    font-size: 2.4rem;
    margin-bottom: 0.4rem;
    line-height: 1.1;
}

.details-overview p {
    display: flex;
    align-items: center;
    gap: 0.8rem;
}

.rating {
    background-color: var(--color-background-100);
    border-radius: 0.9rem;
    padding: 2rem 2.4rem;
    margin-bottom: 0.8rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
}

.btn-add {
    background-color: var(--color-primary);
    color: var(--color-text);
    border: none;
    border-radius: 10rem;
    font-size: 1.4rem;
    padding: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
}

.btn-add:hover {
    background-color: var(--color-primary-light);
}

.btn-reset {
    background-color: var(--color-red);
    color: var(--color-background-900);
    border: none;
    border-radius: 10rem;
    font-size: 1.4rem;
    padding: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
}

.btn-reset:hover {
    background-color: var(--color-red-dark);
}

.seconds {
    background-color: var(--color-background-100);
    width: 8rem;
    border-radius: 10rem;
    font-weight: 600;
    text-align: center;
    padding: 0.4rem;
    margin-top: 2.4rem;
}

.btn-back {
    position: absolute;
    top: 0.6rem;
    left: 0.6rem;
    height: 3.2rem;
    aspect-ratio: 1;
    border-radius: 50%;
    border: none;
    /* background-color: var(--color-text); */
    /*background-color: #fff;*/
    background-color: red;
    color: var(--color-background-500);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.8);
    font-family: sans-serif;
    font-size: 2.4rem;
    font-weight: bold;
    cursor: pointer;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* App.css */
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.adj {
    padding: 20%;
}

.container {
    display: flex;
    justify-content: space-between;
    /*align-items: center;*/
    width: 90%;
    /*height: 100vh;*/
    padding: 100px;
    box-sizing: border-box;
}

.download-container {
    text-align: center;
    margin-top: auto;
}

.download-buttons {
    display: inline-block;
}

.download-buttons a {
    margin: 0 10px;
}

.splash {
    height: 1200px;
    width: 1000px
}

.tag-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 400px;
    margin: 0 auto;
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    list-style-type: none;
    padding: 0;
}

.tag {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    background-color: green;
    border-radius: 15px;
    padding: 0.5rem 1rem;
}

.remove-tag {
    background: none;
    border: none;
    font-size: 2rem;
    margin-left: 0.5rem;
    cursor: pointer;
}

input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Hide default input */
.custom-file-upload input[type="file"] {
    display: none;
}

/* Custom container styling */
.file-upload-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* Custom label styling */
.custom-file-upload {
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 15px;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
    background-color: #0056b3;
}

/* Custom file name display */
.custom-file-upload span {
    padding-left: 5px;
}

/* Upload button styling */
.upload-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.upload-button:hover {
    background-color: #218838;
}

/*.block1, .block2 {*/
/*    width: 45%;*/
/*    height: 200px;*/
/*}*/

/*.block1 {*/
/*    z-index: 1;*/
/*}*/

/*.block2 {*/
/*    z-index: 0;*/
/*}*/

@media (max-width: 768px) {
    .box {
        width: 30rem;
        max-width: 30rem;
        background-color: var(--color-background-500);
        border-radius: 0.9rem;
        overflow: scroll;
        position: relative;
    }
    .phone {
        display: none;
    }

    .splash {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100vh; /* Full viewport height */
    }
    .container {
        flex-direction: column;
        padding: 0;
        padding-right: 10%;
        /*justify-content: flex-start;*/
        /*align-items: center;*/
    }

    .block1, .block2 {
        /*width: 20%;*/
    }

    .block2 {
        /*width: 50%;*/
        /*padding-left: 100%;*/
        /*height: 100px;*/
        margin-top: auto; /* Pushes block2 to the bottom */
    }

    .footer{
        /*display: none;*/
        position:absolute;
        bottom:-10%;
    }
}

/*
SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 40 / 48 / 64 / 80 / 96 / 128

FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 /52 / 62 / 74 / 86 / 98
*/
